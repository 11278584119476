<template>
  <div class="center grow d-col page_container nonwrap">
    <img class="logo" src="img/static/logo-landing.svg" alt="" />
    <div v-for="(item, idx) in items" :key="item.idx">
      <img v-if="idx == active" class="landingImg" :src="item.image" alt="" />
      <div v-if="idx == active" class="startVideo">
        {{ item.text }}
      </div>
    </div>
    <div class="d-flex">
      <div
        class="oval"
        v-for="index in 3"
        :key="index"
        :class="{ active: active == index - 1 }"
      ></div>
    </div>
    <div class="buttons d-flex center my-20 w100">
      <s-btn
        v-for="(item, idx) in buttons"
        :key="idx"
        width="269"
        height="50"
        icon
        class="ma-7 greenHover"
        :title="tip && item.tip"
        @click.native="router(item.route)"
        @mouseover.native="active = idx"
        :class="{ active: active == idx }"
      >
        <s-icon width="20" color="white">{{ item.icon }}</s-icon>
        <div class="grow px-10">
          {{ item.text }}
        </div>
      </s-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    router(val) {
      if (val == "meeting") {
        this.$store.commit("modals/meetingModal", true);
        return;
      }
      this.$router.push(val);
    },
  },
  computed: {
    ...mapState("user", ["tip"]),
    items() {
      return [
        {
          text: this.$t("dashboard.msg1"),
          image: "img/static/graphic-meet-now.svg",
        },
        {
          text: this.$t("dashboard.msg2"),
          image: "img/static/graphic-schedule-meeting.svg",
        },
        {
          text: this.$t("dashboard.msg3"),
          image: "img/static/graphic-go-to-calendar.svg",
        },
      ];
    },
    buttons() {
      return [
        {
          text: this.$t("dashboard.start"),
          tip: this.$t("dashboard.tip1"),
          route: "meeting",
          icon: "video-outline",
        },
        {
          text: this.$t("dashboard.schedule"),
          tip: this.$t("dashboard.tip2"),
          route: "schedule",
          icon: "calendar-plus",
        },
        {
          text: this.$t("dashboard.calendar"),
          tip: this.$t("dashboard.tip3"),
          route: "calendar",
          icon: "calendar-week",
        },
      ];
    },
  },
};
</script>

<style scoped lang='less'>
.oval {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #3f4b58;
  margin: 20px;
}

.startVideo {
  display: flex;
  align-items: center;
  max-width: 600px;
  height: 120px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.landingImg {
  width: 100%;
  max-width: 537px;
  padding: 20px;
  box-sizing: border-box;
  min-height: 300px;
}

.logo {
  width: 100%;
  max-width: 342px;
  padding: 20px;
  box-sizing: border-box;
}

.active {
  background-color: #409875;
}

@media only screen and (max-height: 820px) {
  .buttons {
    order: -1;
  }

  .logo {
    order: -2;
  }
}

@media only screen and (max-width: 576px) {
  .buttons {
    order: -1;
  }

  .logo {
    order: -2;
  }
}
</style>