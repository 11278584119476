<template>
  <div class="Welcome_page d-flex d-col h100 vh100">
    <TopToolbar />
    <div class="d-flex grow">
      <Sidebar />
      <Welcome />
    </div>
    <Footer />
  </div>
</template>

<script>
import Sidebar from "@/components/Toolbars/Sidebar";
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Footer from "@/components/Toolbars/Footer.vue";
import Welcome from "@/components/Welcome/WelcomeComp.vue";

export default {
  name: "Meeting",
  components: {
    Sidebar,
    TopToolbar,
    Welcome,
    Footer,
  },
};
</script>

<style lang="less">

</style>
